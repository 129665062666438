import 'raf/polyfill'
import 'setimmediate'
import { useResources } from 'app/utils/resources'

const fixReanimatedIssue = () => {
  // FIXME remove this once this reanimated fix gets released
  // https://github.com/software-mansion/react-native-reanimated/issues/3355
  if (typeof window !== 'undefined') {
    // @ts-ignore
    window._frameTimestamp = null
  }
}

fixReanimatedIssue()

import { Provider } from 'app/provider'
import Head from 'next/head'
import React from 'react'
import { Amplify } from 'aws-amplify'
import aws from 'app/utils/aws-exports'

import '../global.css'
import '../fonts.css'
import { AppProps } from 'next/app'
import { usePostHog } from 'next-use-posthog'

import nextI18NextConfig from '../next-i18next.config.js'
import { appWithTranslation } from 'next-i18next'
import { Analytics } from '@vercel/analytics/react'

Amplify.configure({
  Auth: {
    region: aws.aws_region,
    userPoolId: aws.aws_user_pools_id,
    userPoolWebClientId: aws.aws_user_pools_web_client_id,
  },
  ssr: true,
})

function MyApp({ Component, pageProps }: AppProps) {
  const { isFontReady } = useResources()

  const locale = pageProps._nextI18Next?.initialLocale ?? 'sv'

  usePostHog('phc_uX4k90pMrimg5w0ZweoSeBiSLStZxLP692t45bG1gGx', {
    api_host: 'https://ux.liveday.se',
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.opt_out_capturing()
    },
  })

  if (!isFontReady) {
    return null
  }

  return (
    <>
      <Head>
        <title>LiveDay - Framtidens biljettsystem</title>
        <meta
          name="description"
          content="LiveDay - Framtidens biljettsystem."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Provider lang={locale}>
        <Component {...pageProps} />
      </Provider>
      <Analytics />
    </>
  )
}

export default appWithTranslation(MyApp, nextI18NextConfig)
