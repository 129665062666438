module.exports = {
    //debug: process.env.NODE_ENV === 'development',
    i18n: {
      defaultLocale: 'en',
      locales: ['ar', 'cs', 'da', 'de', 'en', 'es', 'et', 'fi', 'fr', 'is', 'it', 'ja', 'ko', 'lt', 'lv', 'no', 'pt', 'ru', 'sv', 'zh', 'nl'],
    },
    localePath: typeof window === 'undefined' ?
    require('path').resolve('./public/locales') : './locales',
    reloadOnPrerender: process.env.NODE_ENV === 'development',
  };
  