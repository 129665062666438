// on Web, we don't use React Navigation, so we avoid the provider altogether
// instead, we just have a no-op here
// for more, see: https://solito.dev/recipes/tree-shaking

import { COLOR_MODE } from 'app/types/theme'

export const NavigationProvider = ({
  theme,
  children,
}: {
  theme: COLOR_MODE
  children: React.ReactElement
}) => (
  <div className={theme} id="themeWrapper">
    {children}
  </div>
)
